import { graphql } from "gatsby";
import React from "react";
import { SEO } from "../components/SEO";
import { CollageQuery } from "../types/graphql";
import ImageGallery from "react-image-gallery";

export const query = graphql`
  query collageFiles {
    allFile(filter: { relativeDirectory: { regex: "/img/collage/" } }) {
      group(field: name) {
        edges {
          node {
            publicURL
            relativeDirectory
          }
        }
      }
    }
  }
`;

type Props = {
  data: CollageQuery;
};

function Homepage({ data }: Props): React.ReactElement {
  const images = data.allFile.group.map((group: any) => {
    const node1 = group.edges[0].node;
    const node2 = group.edges[1].node;
    return {
      original: node2.relativeDirectory.includes("thumb")
        ? node1.publicURL
        : node2.publicURL,
      thumbnail: node1.relativeDirectory.includes("thumb")
        ? node1.publicURL
        : node2.publicURL
    };
  });

  return (
    <>
      <SEO title="Startseite" metaDescription="Startseite" />

      <div className="flex flex-col m-3">
        <span className="text-3xl">
          Herzlich Willkommen bei Schmiedekunst Ohlsen
        </span>
        <p className="mb-3">
          Auf meiner Internetseite habt Ihr die Gelegenheit einen kleinen
          Einblick in meine Arbeit zu bekommen.
        </p>
        <ImageGallery items={images} autoPlay showBullets />
      </div>
    </>
  );
}

export default Homepage;
